import * as SignalRClient from '@microsoft/signalr'
import { fetchProject } from 'features/BillOfMaterials/store/asyncActions/fetchProject'
import { newProjectActions } from 'features/BillOfMaterials/store/projectReducer'
import _ from 'lodash'
import { baseUrls } from 'services/servicesBaseUrl'
import { GetAccessToken, ShowException } from 'store/Application/appActions'
import { store } from 'store/configureStore'
import { Debugger } from 'utils/debugger'
import { NetworkActions } from '../store/ConnectionReducer'

export class SignalRService {
  private _connection: SignalRClient.HubConnection
  private hub = 'live-updates'
  private static _client: SignalRService
  private _currentConnectedGroups: string[] = []

  public static async GetHub() {
    if (!SignalRService._client) {
      const hub = new SignalRService()
      SignalRService._client = hub
      await SignalRService._client.StartConnection()
    }

    return SignalRService._client
  }

  private async documentVisibilityChangedHandler() {
    if (
      !document.hidden &&
      this._connection.state !== SignalRClient.HubConnectionState.Connected
    ) {
      console.info('reconnecting after document visibility changed', {
        currentState: this._connection.state,
        connectedGroups: this._currentConnectedGroups,
      })

      await this.ReconnectToGroupsHandler()

      if (store.getState().project?.activeProject?.id) {
        store.dispatch(
          fetchProject({
            projectId: store.getState().project.activeProject.id,
          })
        )

        store.dispatch(
          newProjectActions.updateOperationsFlags({
            shallUpdateOperationsLogs: true,
            shallUpdateOperationsSummary: true,
          })
        )
      }
    }
  }

  private constructor() {
    document.addEventListener('visibilitychange', async () => {
      await this.documentVisibilityChangedHandler()
    })

    this._connection = new SignalRClient.HubConnectionBuilder()
      .withUrl(`${baseUrls.messageHub}/live-updates`, {
        accessTokenFactory: GetAccessToken,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (document.hidden) {
            store.dispatch(
              NetworkActions.SetOnlineStatus({
                connectionState: 'disconnected',
                error: new Error('idle'),
              })
            )

            return null
          }

          if (retryContext.previousRetryCount < 10) {
            const nextAttemptTimeInSeconds = 5 //Math.pow(2, retryContext.previousRetryCount + 1)
            if (retryContext.previousRetryCount > 4)
              store.dispatch(
                NetworkActions.SetOnlineStatus({
                  connectionState: 'reconnecting',
                  error: retryContext.retryReason,
                  retryDescription: `attempt ${
                    retryContext.previousRetryCount + 1
                  }/10. Next attempt in ${nextAttemptTimeInSeconds}s`,
                  retryCount: retryContext.previousRetryCount + 1,
                })
              )
            return nextAttemptTimeInSeconds * 1000
          } else {
            store.dispatch(
              NetworkActions.SetOnlineStatus({
                connectionState: 'disconnected',
                error: retryContext.retryReason,
                retryDescription: null,
                retryCount: retryContext.previousRetryCount + 1,
              })
            )

            return null
          }
        },
      })
      .configureLogging(SignalRClient.LogLevel.Information)
      .build()

    this.StartConnection = this.StartConnection.bind(this)
    this.unregister = this.unregister.bind(this)
    this.Disconnect = this.Disconnect.bind(this)
    this.send = this.send.bind(this)
    this.registerHandler = this.registerHandler.bind(this)
    this.JoinGroup = this.JoinGroup.bind(this)
    this.LeaveGroup = this.LeaveGroup.bind(this)
    this.ReconnectToGroupsHandler = this.ReconnectToGroupsHandler.bind(this)

    window['rh24Connection'] = () => ({
      state: this._connection.state,
      groups: this._currentConnectedGroups,
    })
  }

  public async StartConnection() {
    try {
      if (
        this._connection.state !== SignalRClient.HubConnectionState.Disconnected
      ) {
        return
      }

      await this._connection.start()

      this._connection.keepAliveIntervalInMilliseconds = 30_000
      this._connection.serverTimeoutInMilliseconds = 90_000

      store.dispatch(
        NetworkActions.SetOnlineStatus({
          connectionState: 'online',
        })
      )

      this._connection.onclose((err) => {
        Debugger.Log('info', '[signalR]: onClose', err)
        store.dispatch(
          NetworkActions.SetOnlineStatus({
            connectionState: 'disconnected',
            error: err,
          })
        )
      })

      this._connection.onreconnecting((err) => {
        Debugger.Log('info', '[signalR]: onReconnecting', err)
        // store.dispatch(SetConnectionStatus('reconnecting', err))
      })
    } catch (err) {
      Debugger.Log('error', `Connection to ${this.hub} failed`, err)

      if (err instanceof Error) {
        store.dispatch(
          NetworkActions.SetOnlineStatus({
            connectionState: 'offline',
            error: err,
          })
        )
      }

      throw err
    }
  }

  public async Disconnect() {
    try {
      await this._connection.stop()
    } catch (err) {
      Debugger.Log('error', `Connection to ${this.hub} failed to stop`, err)
    }
  }

  public async send(methodName: string, ...args: unknown[]) {
    try {
      Debugger.Log('info', `[signalr:send]${methodName}`, ...args)

      let retryCount = 1

      if (retryCount > 3) {
        Debugger.Log(
          'error',
          `unable to send signalr messages when connection state is ${this._connection.state}`
        )
      }

      if (
        this._connection.state !== SignalRClient.HubConnectionState.Connected
      ) {
        setTimeout(async () => {
          retryCount++
          Debugger.Log(
            'warning',
            `unable to send signalr messages when connection state is ${this._connection.state}}. Will retry in 2000ms. Attempt ${retryCount}`
          )
          await this.StartConnection()
          await this.send(methodName, ...args)
        }, 5000)
      } else {
        this._connection.send(methodName, ...args)
      }
    } catch (err) {
      Debugger.Log('error', `Unable to send a signalr message`, err)
      throw err
    }
  }

  private async ReconnectToGroupsHandler() {
    Debugger.Log(
      'warning',
      'reconnecting to groups',
      this._currentConnectedGroups
    )
    // console.log('reconnecting to groups', this._currentConnectedGroups)
    const connectToGroupTasks = this._currentConnectedGroups.map(
      (groupName) => {
        return this.JoinGroup(
          groupName,
          () => Debugger.Log('info', `reconnected to group ${groupName}`),
          true
        )
      }
    )

    await Promise.all(connectToGroupTasks)
    store.dispatch(
      NetworkActions.SetOnlineStatus({
        connectionState: 'online',
      })
    )
  }

  private _working = false
  public async JoinGroup(
    groupName: string,
    onEnterGroupHandler: () => void,
    force?: boolean
  ) {
    try {
      if (!force && this._currentConnectedGroups.includes(groupName)) {
        return
      }

      const handler = () => {
        store.dispatch(
          NetworkActions.AddGroup({
            group: groupName,
          })
        )
        onEnterGroupHandler && onEnterGroupHandler()
      }

      // this.unregister(`onEnterGroup-${groupName}`)
      this.unregister(`onentergroup-${groupName}`)

      // this.registerHandler(`onEnterGroup-${groupName}`, handler)
      // yeah, seems like backend is sending this event all lower case
      this.registerHandler(`onentergroup-${groupName}`, handler)

      this._working = true

      await this.send('JoinGroup', groupName)
      this._currentConnectedGroups = _.uniq([
        ...this._currentConnectedGroups,
        groupName,
      ])
      this._connection.onreconnected(() => this.ReconnectToGroupsHandler())

      this._working = false
    } catch (ex) {
      ShowException('signalr', ex)
    }
  }

  public async LeaveGroup(groupName: string, onLeaveGroupHandler: () => void) {
    try {
      if (!this._currentConnectedGroups.includes(groupName)) {
        return
      }

      this._currentConnectedGroups = this._currentConnectedGroups.filter(
        (group) => groupName !== group
      )

      this.registerHandler(`onLeaveGroup-${groupName}`, () => {
        store.dispatch(
          NetworkActions.RemoveGroup({
            group: groupName,
          })
        )

        onLeaveGroupHandler && onLeaveGroupHandler()
        this.unregister(`onentergroup-${groupName}`)
      })

      await this.send('LeaveGroup', groupName)
    } catch (err) {
      console.error(err)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerHandler(methodName: string, action: (...args: any[]) => void) {
    this._connection.on(methodName, action)
    return this
  }

  public unregister(methodName: string) {
    this._connection.off(methodName)
  }
}
